@keyframes slideLeft {
    0% {
      left: -200%;
    }
    100% {
      left: -25%;
    }
  }
  
  @keyframes slideRight {
    0% {
      right: -200%;
    }
    100% {
      right: -25%;
    }
  }
  
  @keyframes popIn {
    0% {
      transform: translateY(400px);
      opacity: 0;
      z-index: 1;
    }
    80% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 600px;
    background:skyblue;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  .box h3 {
    animation: popIn 1s forwards;
    animation-delay: 1.4s;
    transform: translateY(200px);
    backface-visibility: hidden;
    text-align: center;
    color: white;
    z-index: 9999;
    text-transform: uppercase;
    font-size: 5rem;
    opacity: 0;
    position: absolute;
    top: 0px;
    z-index: 1;
  }
  .box p{
    font-size: 20px ;
   

  }
  @media screen and (max-width: 1300px) {
    .box h3 {
      margin-top: -50px;

    }

  }
  @media screen and (max-width: 700px) {
    .box h3 {
      font-size: 2rem;
      top: 200px;
    }

  }
  .box:before {
    transform: skew(60deg);
    transform-origin: left;
    animation: slideLeft 0.4s ease forwards;
    animation-delay: 0.2s;
    content: "";
    position: absolute;
    top: 0;
    left: -200%;
    width: 60%;
    height: 100%;
    background:rgb(12, 154, 210); /* Assuming you want a fixed color here */
    z-index: 1;
  }
  .box:after {
    transform: skew(-60deg);
    transform-origin: right;
    animation: slideRight 0.4s ease forwards;
    animation-delay: 0.6s;
    content: "";
    position: absolute;
    top: 0;
    right: -200%;
    width: 60%;
    height: 100%;
    background: rgb(12, 154, 210); /* Assuming you want a fixed color here */
    z-index: 10;
  }
 