
.modal-header {
  /* background-color: #343a40; Dark background color */
  color: black; /* Light text color */
  padding: 15px;
  margin: 50px 0 ;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
}
.more-info-btn{
  background-color: #004AAD;
  color: white;
}

.close {
  background-color:#004AAD;
  border: none;
  color: white;
  font-size: 25px;
  cursor: pointer;
}
.close:hover{
  background-color: black;
}
/* Style for the modal body */
.modal-body {
  padding: 20px;
}

/* Style for the order details */
.order-details p {
  margin-bottom: 10px;
  font-size: 18px;
}

.order-details h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.order-details ul {
  list-style-type: none;
  padding: 0;
}

.order-details li {
  margin-bottom: 15px;
  
}

.select-wrapper {
  position: relative;
  display: inline-block;
}

.status-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  color: #495057;
}

.status-dropdown:hover {
  border-color: #6c757d;
}

.status-dropdown:focus {
  outline: none;
  border-color: #6c757d;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}

/* Styling for the update button */
.update-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 8px;
}

.update-button:hover {
  background-color: #218838;
}

/* Styling for the edit icon */
.edit-icon {
  margin-left: 8px;
  cursor: pointer;
  font-size: 30px;
  color: black;
}

.edit-icon:hover {
  color: #0056b3;
}
.visible {
  display: inline-block;
}

.hidden {
  display: none;
}

.image-container {
  display: flex;
  flex-wrap: wrap; /* Allows images to wrap to the next line if there is not enough space */
}

.modal-image {
  max-width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;

}

/* .option-buttons{
  display: flex !important;
  justify-content: space-between !important;
  gap: 30px;
} */
.product-list-btn{
  width: 150px;
  /* margin-top:10px ; */
  margin-left: 50px;
}


.table-data-inputs input {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgba(128, 128, 128, 0.259);
  /* border: none; */
}
.table-data-inputs select {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgba(128, 128, 128, 0.259);
  /* border: none; */
}


.table-data-inputs input:hover {
  border-color: #004AAD;
}

.table-data-inputs input:focus {
  outline: none;
  border-color: #004AAD;
  box-shadow: 1px 1px 20px  #004bad86
}
.order-status-container{

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.UpdateStatusBtn{
  font-weight: 600;
  color: #fff;
  text-align: center;
  min-width: 116px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid;
  background-color: #004AAD;
  border-color: #004AAD;
  border-radius: 10px;
  line-height: 35px;
  font-size: 14px;
}




.pre-order-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 50px;
}

.pre-order-heading {
  text-align: center;
}

.pre-order-label {
  display: block;
  margin-bottom: 10px;
}

.pre-order-input {
  width: 100%;
  height: 50px;
  padding: 0px 10px;
  border-radius: 5px;
  border: 1px solid gray;
}

.pre-order-button {
  background: #004AAD;
  color: white;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
