
.about-card-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 50px;
    background-color: white;
  }
  
  .about-card {
    width: 30%;
    min-height: 200px;
    text-align: center;
  
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 6px;
    transition: transform 0.3s ease-in-out;
  }
  .about-card p{
      font-size: 20px;
  }
  
  .about-card:hover {
    transform: scale(1.05);
  }
  .location-icon{
      font-size: 30px;
      margin-bottom: 5px;
      color: #C37B7F;
  }
  .phone-icon{
      font-size: 30px;
      margin-bottom: 5px;
      color: #76BA64;
  }
  .email-icon{
      font-size: 30px;
      margin-bottom: 5px;
      color: red;
  }
  .second-about-container{
    position: relative;
    left: 30px;
    top: 60px;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    background-color: white;
    padding: 20px;
  }
  
  .contact-form {
    padding: 30px 40px;
    background-color: #ffffff;
    border-radius: 12px;
    max-width: 450px;
  }
  .map-container{
    width: 100%;
  }
  
  .contact-form textarea {
    resize: none;
  }
  
  .contact-form .form-input,
  .form-text-area {
    background-color: #f0f4f5;
    height: 50px;
    padding-left: 16px;
  }
  
  .contact-form .form-text-area {
    background-color: #f0f4f5;
    height: auto;
    padding-left: 16px;
  }
  
  .contact-form .form-control::placeholder {
    color: #aeb4b9;
    font-weight: 500;
    opacity: 1;
  }
  
  .contact-form .form-control:-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control::-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control:focus {
    border-color: #004AAD;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #004AAD;
  }
  
  .contact-form .title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }
  
  .contact-form .description {
    color: #aeb4b9;
    font-size: 14px;
    text-align: center;
  }
  
  .contact-form .submit-button-wrapper {
    text-align: center;
  }
  
  .contact-form .submit-button-wrapper input {
    border: none;
    border-radius: 4px;
    background-color:#004AAD;
    color: white;
    text-transform: uppercase;
    padding: 10px 60px;
    font-weight: 500;
    letter-spacing: 2px;
  }
  
  .contact-form .submit-button-wrapper input:hover {
    background-color: #004AAD;
  }
  
  
  @media screen and (max-width: 600px) {
    .about-card-container{
      margin-top: 0px;
    }
    .first-image-container {
height: 130px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .about-card {
      width: 45%;
    }
    .second-about-container{
      display: block;
    }
    .map-container{
      width: 100%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .about-card {
      width: 100%;
    }
    .second-about-container{
      display: block;
      left: 0;
    }
  }
  