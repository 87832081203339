/* 

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}*

body{
    background-color: #c9d6ff;
    background: linear-gradient(to right, #e2e2e2, #c9d6ff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
} */

.password-input-container {
    position: relative;
  }
  
  .password-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  

.login-page-container{
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

.login-center{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-page-container p{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.login-page-container span{
    font-size: 12px;
}

.login-page-container a{
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
}

.login-page-container button{
    background-color: #004AAD ;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.login-page-container button.hidden{
    background-color: transparent;
    border-color: #fff;
}

.login-page-container form{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
}

.login-page-container input{
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    height: 40px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}
.login-page-container input[type="password"]{
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    height: 40px;
    font-size: 25px !important;
    border-radius: 8px;
    width: 100%;
    outline: none;
}
.login-page-container input[type="text"]{
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    height: 40px;
    font-size: 25px !important;
    border-radius: 8px;
    width: 100%;
    outline: none;
}

.form-container{
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in{
    left: 0;
    width: 50%;
    z-index: 2;
}

.login-page-container.active .sign-in{
    transform: translateX(100%);
}

.sign-up{
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    transition: all 0.5s;
}

.login-page-container.active .sign-up{
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: move 0.6s;
}

@keyframes move{
    0%, 49.99%{
        opacity: 0;
        z-index: 1;
    }
    50%, 100%{
        opacity: 1;
        z-index: 5;
    }
}

.social-icons{
    margin: 20px 0;
}

.social-icons a{
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
    transition: all 0.5s;
}

.social-icons a:hover{
    scale: 1.3;
    border: 1px solid #000;
}

.toggle-container{
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 100px;
    z-index: 1000;
}

.login-page-container.active .toggle-container{
    transform: translateX(-100%);
    border-radius: 0 150px 100px 0;
}

.toggle{
    background-color: #004AAD ;
    height: 100%;
    background: linear-gradient(to right, #5c6bc0, #004AAD );
    color: #fff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.login-page-container.active .toggle{
    transform: translateX(50%);
}

.toggle-panel{
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.toggle-left{
    transform: translateX(-200%);
}

.container.active .toggle-left{
    transform: translateX(0);
}

.toggle-right{
    right: 0;
    transform: translateX(0);
}

.login-page-container.active .toggle-right{
    transform: translateX(200%);
}


@media (max-width: 600px){
  .login-page-container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
    min-width: 100vw !important;
}
.toggle{
  display: none;
}
.login-page-container form ,.sign-in{
  width: 100%;
}

}
@media (min-width: 600px){
  .login-page-container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
    min-width: 600px !important;
}
}

@media (min-width: 768px){
  .login-page-container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
    min-width: 700px !important;
}
}

@media (min-width: 900px){
  .login-page-container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
    min-width: 800px !important;
}
}

@media (min-width: 1400px){
  .login-page-container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
    min-width: 900px !important;
}
}









/* ***************************************************************************************************** */



.login-container {
    max-width: 400px;
    margin: auto;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .label {
    margin-bottom: 8px;
  }
  
  .input {
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .error-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #ff9999;
    color: #cc0000;
    border: 1px solid #cc0000;
    border-radius: 4px;
  }
  