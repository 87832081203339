* {
    box-sizing: border-box;
  }
  
  html {
    font-size: 12px;
  }
  
  body {
    margin: 20px 0;
    padding: 0;
    font-family: arial, sans-serif;
    /* overflow: scroll; */
  }
  
  img , .cartImg{
    max-width: 100%;
    vertical-align: middle;
    border-radius: 4px;
    height: 100px;
  }
  
  a {
    text-decoration: none;
    color: #333333;
  }
  
  a:hover {
    color: #004AAD;
  }
  
  .cartcartButton {
    background-color: #16cc9b;
    border: 2px solid #16cc9b;
    color: #ffffff;
    transition: all 0.25s linear;
    cursor: pointer;
  }
  
  .cartButton::after {
    position: relative;
    right: 0;
    content: " \276f";
    transition: all 0.15s linear;
  }
  
  .cartButton:hover {
    background-color: #004AAD;
    border-color: #004AAD;
  }
  
  .cartButton:hover::after {
    right: -5px;
  }
  
  .cartButton:focus {
    outline: none;
  }
  
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  input {
    transition: all 0.25s linear;
  }
  
  input[type="number"]::-webkit-inner-spin-cartButton,
  input[type="number"]::-webkit-outer-spin-cartButton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  input {
    outline: none;
  }
  
  .ourcontainer {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
    overflow: auto;
  }
  .header {
    width: 90%;
    margin: 0 auto;
    /* overflow: auto; */
  }
 
  /* --- HEADER --- */
  header.ourcontainer {
    margin-bottom: 1.5rem;
  }
  
  header .breadcrumb {
    color: #7d7d7d;
  }
  
  header .breadcrumb li {
    float: left;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
  }
  
  header .breadcrumb li:first-child {
    padding-left: 2px;
  }
  
  header .breadcrumb li:not(:last-child)::after {
    content: " \276f";
    padding-left: 8px;
  }
  
  header .count {
    float: right;
    color: #333333;
    height: 20px;
    line-height: 20px;
  }
  
  /* --- PRODUCT LIST --- */
  .products {
    border-top: 1px solid #ddd;
  }
  
  .products > li {
    /* padding: 1rem 0; */
    border-bottom: 1px solid #ddd;
  }
  
  .row {
    position: relative;
    /* overflow: auto; */
    width: 100%;
  }
  
  .col,
  .quantity,
  .remove {
    float: left;
  }
  
  .col.left {
    width: 70%;
  }
  
  .col.right {
    width: 30%;
    position: absolute;
    right: 0;
    top: calc(50% - 30px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .detail {
    padding: 0 0.5rem;
    line-height: 2.2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .detail .name {
    font-size: 1.2rem;
  }
  
  .detail .description {
    color: #7d7d7d;
    font-size: 1rem;
  }
  
 .price {
    font-size: 1.5rem;
  }
  
  .quantity,
  .remove {
    width: 50%;
    text-align: center;
  }
  
  .remove svg {
    width: 60px;
    height: 60px;
  }
  
  .quantity > input {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    left: calc(50% - 30px);
    background: #fff;
    border: 2px solid #ddd;
    color: #7f7f7f;
    text-align: center;
    font: 600 1.5rem Helvetica, Arial, sans-serif;
  }
  
  .quantity > input:hover,
  .quantity > input:focus {
    border-color: #004AAD;
  }
  
  .close {
    fill: white;
    transition: color 150ms linear, background-color 150ms linear,
      fill 150ms linear, 150ms opacity linear;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .close:hover {
    fill: white;
  }
  
  /* --- SUMMARY --- */
  .promotion,
  .summary,
  .checkout {
    float: left;
    width: 100%;
    margin-top: 1.5rem;
  }
  
  .promotion > label {
    float: left;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .promotion > input {
    float: left;
    width: 80%;
    font-size: 1rem;
    padding: 0.5rem 0 0.5rem 1.8rem;
    border: 2px solid #16cc9b;
    border-radius: 2rem 0 0 2rem;
  }
  
  .promotion:hover > input {
    border-color: #004AAD;
  }
  
  .promotion > cartButton {
    float: left;
    width: 20%;
    height: 2.4rem;
    border-radius: 0 2rem 2rem 0;
  }
  
  .promotion:hover > cartButton {
    border-color: #004AAD;
    background-color: #004AAD;
  }
  
  .promotion > cartButton::after {
    content: "\276f";
    font-size: 1rem;
  }
  
  .summary {
    font-size: 1.2rem;
    text-align: right;
  }
  
  .summary ul li {
    padding: 0.2rem 0;
  }
  
  .summary ul li span {
    display: inline-block;
    width: 30%;
  }
  
  .summary ul li.total {
    font-weight: bold;
  }
  
  .checkout {
    text-align: right;
    display: flex;
    justify-content: end;
  }
  
  .checkout > .cartButton {
    font-size: 1.2rem;
    padding: 0.8rem 2.8rem;
    /* border-radius: 1.5rem; */
    background-color: #004AAD;
    color: #fff;
    border-radius: 10px;

  }
  .checkout > .cartButton:disabled {
 background-color: lightgrey;
  }
  
  .empty-product {
    text-align: center;
  }
  
  .empty-product > .cartButton {
    font-size: 1.3rem;
    padding: 10px 30px;
    border-radius: 5px;
  }

  .emptyCart-div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .emptyCart-div img{
   width: 70%;
   height: 580px;
   margin: auto;
  }
  
  /* --- SMALL SCREEN --- */
  @media all and (max-width: 599px) {
  
    .emptyCart-div img{
      width: 100vw;
      height: 250px;
    }
    .quantity > input {
      width: 40px;
      height: 40px;
      left: calc(50% - 20px);
    }
    .thumbnail,
    .detail {
      float: left;
      width: 25% !important;
      align-items: center;
    }
  
    .remove svg {
      width: 40px;
      height: 40px;
    }
  }
  
  /* --- MEDIUM & LARGE SCREEN --- */
  @media all and (min-width: 600px) {


    html {
      font-size: 14px;
    }
  
    .ourcontainer {
      width: 75%;
      max-width: 960px;
    }
  
    .thumbnail,
    .detail {
      float: left;
      width: 25% !important;
      align-items: center;
    }
  
    .detail {
      width: 65%;
    }
  
    .promotion,
    .summary {
      width: 100%;
    }
  
    .checkout {
      width: 100%;
    }
  
    .checkout,
    .summary {
      text-align: right;
    }
  }

  /* --- LARGE SCREEN --- */
  @media all and (min-width: 992px) {
    html {
      font-size: 16px;
    }

  }
  




@media only screen and (min-width: 300px) and (max-width: 600px) {
 
  .col.right{
    width: 50%;
  }
  .cartImg{
height: 70px;
  }
  .col.left{
display: flex;
padding: 0px !important;
  }
  .detail .name{
    font-size: 10px;
  }
  .detail .price{
    font-size: 12px;
    font-weight: bold;
  }
  .remove{
    width: 20%;
  }
  .quantity{
    width: 80%;
  }
  .quantity .p-counter .qty{
   width: 30px !important;
  }
}


@media only screen and (min-width: 601px) and (max-width: 768px) {
  .emptyCart-div img{

    height: 300px;
  }
  .ourcontainer{
    width: 100%;
  }
  .col.right{
    width: 50%;
  }
  .remove{
    width: 20%;
  }
}


@media only screen and (min-width: 769px) and (max-width: 992px) {
  .ourcontainer{
    width: 100%;
  }
  .emptyCart-div img{

    height: 400px;
   }
}


@media only screen and (min-width: 993px) and (max-width: 1200px) {

  .ourcontainer{
    width: 100%;
  }
  .emptyCart-div img{

    height: 400px;
   }
}


@media only screen and (min-width: 1201px) and (max-width: 1400px) {}


@media only screen and (min-width: 1401px)  {}